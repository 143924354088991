import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../../substitutions.js";
import * as dxos_agent_dashboard from "../agent/dashboard.js";
import * as dxos_agent_echoproxy from "../agent/echoproxy.js";
import * as dxos_agent_epoch from "../agent/epoch.js";
import * as dxos_agent_functions from "../agent/functions.js";
import * as dxos_bot from "../bot.js";
import * as dxos_client_services from "../client/services.js";
import * as dxos_config from "../config.js";
import * as dxos_devtools_host from "../devtools/host.js";
import * as dxos_devtools_swarm from "../devtools/swarm.js";
import * as dxos_echo_blob from "../echo/blob.js";
import * as dxos_echo_feed from "../echo/feed.js";
import * as dxos_echo_filter from "../echo/filter.js";
import * as dxos_echo_indexing from "../echo/indexing.js";
import * as dxos_echo_metadata from "../echo/metadata.js";
import * as dxos_echo_model_document from "../echo/model/document.js";
import * as dxos_echo_model_messenger from "../echo/model/messenger.js";
import * as dxos_echo_model_text from "../echo/model/text.js";
import * as dxos_echo_object from "../echo/object.js";
import * as dxos_echo_query from "../echo/query.js";
import * as dxos_echo_service from "../echo/service.js";
import * as dxos_echo_snapshot from "../echo/snapshot.js";
import * as dxos_echo_timeframe from "../echo/timeframe.js";
import * as dxos_edge_calls from "../edge/calls.js";
import * as dxos_edge_messenger from "../edge/messenger.js";
import * as dxos_edge_signal from "../edge/signal.js";
import * as dxos_error from "../error.js";
import * as dxos_gravity from "../gravity.js";
import * as dxos_halo_credentials from "./credentials.js";
import * as dxos_halo_credentials_auth from "./credentials/auth.js";
import * as dxos_halo_credentials_greet from "./credentials/greet.js";
import * as dxos_halo_credentials_identity from "./credentials/identity.js";
import * as dxos_halo_keyring from "./keyring.js";
import * as dxos_halo_keys from "./keys.js";
import * as dxos_halo_signed from "./signed.js";
import * as dxos_iframe from "../iframe.js";
import * as dxos_keys from "../keys.js";
import * as dxos_mesh_bridge from "../mesh/bridge.js";
import * as dxos_mesh_broadcast from "../mesh/broadcast.js";
import * as dxos_mesh_messaging from "../mesh/messaging.js";
import * as dxos_mesh_muxer from "../mesh/muxer.js";
import * as dxos_mesh_presence from "../mesh/presence.js";
import * as dxos_mesh_protocol from "../mesh/protocol.js";
import * as dxos_mesh_replicator from "../mesh/replicator.js";
import * as dxos_mesh_signal from "../mesh/signal.js";
import * as dxos_mesh_swarm from "../mesh/swarm.js";
import * as dxos_mesh_teleport from "../mesh/teleport.js";
import * as dxos_mesh_teleport_auth from "../mesh/teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "../mesh/teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "../mesh/teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "../mesh/teleport/control.js";
import * as dxos_mesh_teleport_gossip from "../mesh/teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "../mesh/teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "../mesh/teleport/replicator.js";
import * as dxos_registry from "../registry.js";
import * as dxos_rpc from "../rpc.js";
import * as dxos_service_agentmanager from "../service/agentmanager.js";
import * as dxos_service_publisher from "../service/publisher.js";
import * as dxos_service_supervisor from "../service/supervisor.js";
import * as dxos_service_tunnel from "../service/tunnel.js";
import * as dxos_tracing from "../tracing.js";
import * as dxos_type from "../type.js";
import * as dxos_value from "../value.js";
import * as example_testing_data from "../../example/testing/data.js";
import * as example_testing_rpc from "../../example/testing/rpc.js";
import * as google_protobuf from "../../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface InvitationOptions {
    /**
     * Role of the peer.
     */
    role: InvitationOptions.Role;
}
export namespace InvitationOptions {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/halo/invitations.proto}
     */
    export enum Role {
        GUEST = 0,
        HOST = 1
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface IntroductionRequest {
    /**
     * Guest's profile.
     *
     * Options:
     *   - proto3_optional = true
     */
    profile?: dxos_halo_credentials.ProfileDocument;
    /**
     * Options:
     *   - proto3_optional = true
     */
    invitationId?: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface IntroductionResponse {
    authMethod: dxos_client_services.Invitation.AuthMethod;
    /**
     * Present when auth_method is KNOWN_PUBLIC_KEY.
     *
     * Options:
     *   - proto3_optional = true
     */
    challenge?: Uint8Array;
    /**
     * Only present for testing invitation types.
     *
     * Options:
     *   - proto3_optional = true
     */
    spaceKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface AuthenticationRequest {
    /**
     * Present when auth_method is SHARED_SECRET.
     *
     * Options:
     *   - proto3_optional = true
     */
    authCode?: string;
    /**
     * Present when auth_method is KNOWN_PUBLIC_KEY.
     *
     * Options:
     *   - proto3_optional = true
     */
    signedChallenge?: Uint8Array;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface AuthenticationResponse {
    /**
     * Options:
     *   - proto3_optional = true
     */
    status?: AuthenticationResponse.Status;
}
export namespace AuthenticationResponse {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/halo/invitations.proto}
     */
    export enum Status {
        OK = 0,
        INVALID_OTP = 1,
        INVALID_OPT_ATTEMPTS = 2,
        INTERNAL_ERROR = 3,
        INVALID_SIGNATURE = 4
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface DeviceAdmissionRequest {
    deviceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    controlFeedKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    dataFeedKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    profile?: dxos_halo_credentials.DeviceProfileDocument;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface SpaceAdmissionRequest {
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    deviceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    controlFeedKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    dataFeedKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface DelegateSpaceInvitation {
    invitationId: string;
    authMethod: dxos_client_services.Invitation.AuthMethod;
    swarmKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    role: dxos_halo_credentials.SpaceMember.Role;
    /**
     * Present for AuthMethod.KNOWN_PUBLIC_KEY, where guess needs to prove possession of a corresponding private key
     *
     * Options:
     *   - proto3_optional = true
     */
    guestKey?: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    expiresOn?: ReturnType<(typeof substitutions)["google.protobuf.Timestamp"]["decode"]>;
    multiUse: boolean;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface CancelDelegatedInvitation {
    credentialId: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface AdmissionRequest {
    device?: DeviceAdmissionRequest;
    space?: SpaceAdmissionRequest;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface DeviceAdmissionCredentials {
    identityKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    haloSpaceKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    genesisFeedKey: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    /**
     * Options:
     *   - proto3_optional = true
     */
    controlTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
    /**
     * Credential of type `AuthorizedDevice` that allows guest device to act as the identity it was added to.
     */
    credential: dxos_halo_credentials.Credential;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface SpaceAdmissionCredentials {
    /**
     * Credential of type `SpaceMember` that grants Guest's identity access to the space.
     */
    credential: dxos_halo_credentials.Credential;
    /**
     * Hint to the guest about the current control timeframe.
     *
     * Options:
     *   - proto3_optional = true
     */
    controlTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
    /**
     * Hint to the guest about the current data timeframe.
     *
     * Options:
     *   - proto3_optional = true
     */
    dataTimeframe?: ReturnType<(typeof substitutions)["dxos.echo.timeframe.TimeframeVector"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface AdmissionResponse {
    device?: DeviceAdmissionCredentials;
    space?: SpaceAdmissionCredentials;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/halo/invitations.proto}
 */
export interface InvitationHostService {
    /**
     * Both peers must call this method before any other.
     */
    options: (request: InvitationOptions, options?: RequestOptions) => Promise<void>;
    /**
     * Introduce guest to the host. Only on the host.
     */
    introduce: (request: IntroductionRequest, options?: RequestOptions) => Promise<IntroductionResponse>;
    /**
     * Authenticate request. Only on the host.
     */
    authenticate: (request: AuthenticationRequest, options?: RequestOptions) => Promise<AuthenticationResponse>;
    /**
     * Process admission credentials. Only on the host.
     */
    admit: (request: AdmissionRequest, options?: RequestOptions) => Promise<AdmissionResponse>;
}
